<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('menu.whatWeOffer') }}
      </template>
    </RMPageHeader>
    <div class="row justify-content-center">
      <game-offer
          v-for="(game, idx) in gameList"
          :key="idx"
          v-bind:game="game"
          v-bind:game-info="gameInfo"
          v-bind:game-info-loading="gameInfoLoading"
      ></game-offer>
    </div>
  </div>
</template>

<script>
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {getAllGames} from "@/_helpers/games_helper";
import GameOffer from "@/components/games/offer/GameOffer";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "Offer",
  components: {RMPageHeader, GameOffer},
  data() {
    return {
      gameInfoLoading: false,
      gameInfo: {},
    };
  },
  computed: {
    gameList() {
      return getAllGames();
    },
  },
  methods: {
    initOffer() {
      this.gameInfo = {};
      this.gameInfoLoading = true;

      gamesService
          .get_settings("all")
          .then((data) => {
            this.gameInfo = data;
            this.gameInfoLoading = false;
          })
          .catch((error) => {
            this.gameInfoLoading = false;
            handleError(error, router);
          });
    },
  },
  created() {
    this.initOffer();
  },
};
</script>

<style scoped></style>
