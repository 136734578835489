<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 col-xxxl-4">
    <RMPanel v-bind:title="gameName">
      <template v-slot:panelBody>
        <div class="row form-group m-b-10">
          <table class="table">
            <thead>
            <tr>
              <td></td>
              <td><b>{{ $t("common.withoutCarrots") }}</b></td>
              <td><b>{{ $t("common.withCarrots") }}</b></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ $t("games.common.dailyChallenge") }}</td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
            </tr>
            <tr>
              <td>{{ $t("games.common.weeklyChallenge") }}</td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
            </tr>
            <tr>
              <td><b>{{ $t("games.common.creatingNewGame") }}</b></td>
              <td><i class="fa fa-times fa-wrong-rm" aria-hidden="true"></i></td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
            </tr>
            <tr>
              <td><b>{{ $t("games.common.difficultySelection") }}</b></td>
              <td><i class="fa fa-times fa-wrong-rm" aria-hidden="true"></i></td>
              <td><i class="fa fa-check fa-solved-rm" aria-hidden="true"></i></td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </RMPanel>
  </div>
</template>

<script>
import {snakeCase} from "lodash";
import RMPanel from "@/components/common/RMPanel.vue";

export default {
  name: "GameOffer",
  components: {RMPanel},
  props: {
    game: {
      type: Object,
      default: function () {
        return {};
      },
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    gameInfoLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    gameName() {
      return this.$t("games.games." + this.game.id + ".name");
    },
    gameSettings() {
      return this.gameInfo[snakeCase(this.game.id)];
    },
  },
};
</script>

<style scoped></style>
